<template>
    <div
        :style="{
            height: '7.5vh',
            width: '100%',
            background: '#fff',
            position: 'sticky',
            top: '0px',
            background: '#cba77e',
            'line-height': '7vh',
            color: 'rgb(255, 255, 255)',
            'z-index': '99',
        }"
    >
        <title>{{ title }}</title>
        <div
            style="float: left; width: 10%; height: 100%; line-height: 10vh"
            @click="returnUrls"
            v-if="
                url == '/my' ||
                url == '/' ||
                url == '/content' ||
                url == '/task' ||
                content_sore
            "
        >
            <img
                style="width: 44px; height: 44px"
                src="../assets/index/logos.png"
            />
        </div>
        <div
            style="float: left; width: 10%; height: 100%; line-height: 9vh"
            @click="returnUrl"
            v-else-if="!search"
        >
            <img
                src="../assets/header/logo.png"
                v-if="
                    vip ||
                    url == '/my/account' ||
                    set_font_size ||
                    share_day ||
                    share_text_sort ||
                    share_pic_sort ||
                    vippay ||
                    s_topic ||
                    lesson_done ||
                    topic ||
                    lesson ||
                    training_schedule_detail ||
                    training_schedule ||
                    search_result ||
                    share ||
                    add_activation_record ||
                    add_punch_card_project ||
                    regard_allstar ||
                    activation_record_detail ||
                    activation_record ||
                    img ||
                    grade_table ||
                    account ||
                    reset_password ||
                    faq ||
                    point_rule ||
                    collect
                "
            />
        </div>
        <div
            style="float: left; width: 10%; height: 100%; line-height: 10vh"
            @click="returnUrl"
            v-else
        >
            <img src="../assets/header/index_logo.png" />
        </div>
        <!-- <div :class="{allstar:!header_style && status && !add_punch_card_project?true:false,allstars:!status || add_punch_card_project}" ><img src="../assets/content/logo_s.png" style="width:10%;height:100%;"><sapm style="color:#243490;">TONE</sapm><sapm style="color:#1370b8;">3D partners</sapm></div> -->

        <div
            :class="{
                allstar:
                    !header_style && status && !add_punch_card_project
                        ? true
                        : false,
                allstars: !status || add_punch_card_project,
            }"
        >
            <!-- <img src="../assets/content/logo_s.png" style="width:25px;height:25px;vertical-align: middle;"> &nbsp;<sapm style="color:#243490;">3D partners</sapm> -->
            <!-- <img src="../assets/content/c7ef828bced6a434642e5094d093dde.png" style="width:35px;height:35px;vertical-align: middle;"> &nbsp;<span style="color:#fff;">3D partners</span> -->
        </div>

        <div
            class="login"
            v-if="!status && url != '/my' && !img"
            @click="login"
        >
            <router-link :to="{ path: '/login' }">登入</router-link>
        </div>
        <div
            class="user_centent"
            v-else-if="
                url != '/set_font_size' &&
                url != '/my' &&
                url != '/register' &&
                url != '/my/account' &&
                url != '/my/reset_password' &&
                url != '/task/add_punch_card_project' &&
                userName
            "
            @click="UserMy"
        >
            <img
                :src="userAvatar"
                v-if="userAvatar && userAvatar != 'null'"
                style="
                    width: 25px;
                    height: 25px;
                    border-radius: 12px;
                    vertical-align: middle;
                "
            />
            &nbsp;{{ userName }}
        </div>
    </div>
</template>

<style>
img,
svg,
video {
    display: unset;
    vertical-align: unset;
}
.allstars {
    float: left;
    width: 75%;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
}
.allstar {
    float: left;
    width: 55%;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
}
.login {
    float: left;
    width: 15%;
    height: 100%;
    font-size: 14px;
}
.user_centent {
    float: left;
    width: 35%;
    height: 100%;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
}
.user_centents {
    float: left;
    width: 35%;
    height: 100%;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
}
a {
    color: #fff;
    text-decoration: none;
}
</style>
<script>
export default {
    name: "Header",
    component: {},
    data() {
        return {
            title: "3D partners",
            status: true,
            userName: "",
            userAvatar: "",
            // 我的页面 账户页面头部都不显示用户名及头像 为了兼容 有内容和没内容时布局 用改值判断
            header_style: false,
            img: false,
            grade_table: false,
            account: false,
            reset_password: false,
            faq: false,
            point_rule: false,
            // 返回上一級路徑
            return_url: "",
            collect: false,
            activation_record: false,
            activation_record_detail: false,
            regard_allstar: false,
            add_punch_card_project: false,
            add_activation_record: false,
            share: false,
            search: false,
            search_result: false,
            training_schedule: false,
            training_schedule_detail: false,
            lesson: false,
            topic: false,
            lesson_done: false,
            s_topic: false,
            vippay: false,
            share_pic_sort: false,
            share_text_sort: false,
            share_day: false,
            set_font_size: false,
            content_sore: false,
            url: "",
            vip: false,
        };
    },
    created() {
        if (this.$store.state.login_verify) {
            this.userName = this.$store.state.user_name;
            this.userAvatar = this.$store.state.user_avatar;
            this.status = true;
        } else {
            this.status = false;
        }
        // 用於判斷顯示那些界面有返回按鈕
        let url = this.$route.fullPath;
        let type_url = url.split("?");
        let split = url.split("/");
        if (type_url[0] == "/my/account") {
            this.vip = true;
        } else if (split[1].split("=")[0] == "content?sort_id") {
            this.content_sore = true;
        } else if (url == "/my" || url == "/my/account") {
            this.header_style = true;
        } else if (
            url == "/set_font_size" ||
            (split[0] == "" && split[1] == "count_off_today") ||
            (split[0] == "" &&
                split[1] == "task" &&
                split[2] == "add_punch_card_project" &&
                split[3] > 0) ||
            (split[0] == "" &&
                split[1] == "task" &&
                split[2] == "placereport" &&
                split[3] > 0)
        ) {
            this.set_font_size = true;
        } else if (
            (split[1] == "share_day" && split[2] > 0) ||
            (split[0] == "" && split[1] == "share_day")
        ) {
            this.share_day = true;
        } else if (
            url == "/share_text_sort" ||
            type_url[0] == "/share_text_sort"
        ) {
            this.share_pic_sort = true;
        } else if (
            url == "/share_pic_sort" ||
            type_url[0] == "/share_pic_sort"
        ) {
            this.share_pic_sort = true;
        } else if (
            (split[1] == "vippay" || split[1] == "share_text_info") &&
            split[2] > 0
        ) {
            this.vippay = true;
        } else if (split[1] == "s_topic" && split[2] > 0) {
            this.s_topic = true;
        } else if (
            type_url[0] == "/s_topic" &&
            type_url[1].split("id=")[0] == "" &&
            type_url[1].split("id=")[1] > 0
        ) {
            this.s_topic = true;
        } else if (split[1] == "lesson_done" && split[2] > 0) {
            this.lesson_done = true;
        } else if (url == "/topic") {
            // 搜索頁進入課程
            this.topic = true;
        } else if (
            type_url[0] == "/topic" &&
            type_url[1].split("id=")[0] == "" &&
            type_url[1].split("id=")[1] > 0
        ) {
            this.topic = true;
        } else if (split[1] == "topic" && split[2] > 0) {
            this.topic = true;
        } else if (url == "/lesson") {
            this.lesson = true;
        } else if (
            type_url[0] == "/lesson" &&
            type_url[1].split("id=")[0] == "" &&
            type_url[1].split("id=")[1] > 0
        ) {
            this.lesson = true;
        } else if (split[1] == "training_schedule" && split[2] > 0) {
            this.training_schedule_detail = true;
        } else if (
            url == "/training_schedule" ||
            type_url[0] == "/training_schedule"
        ) {
            this.training_schedule = true;
        } else if (
            split[0] == "" &&
            split[1] == "search_result" &&
            split[2].split("index?content=").length == 2
        ) {
            this.search_result = true;
        } else if (url == "/search") {
            this.search = true;
        } else if (url == "/share") {
            this.share = true;
        } else if (url == "/register") {
            this.img = true;
        } else if (url == "/task/grade_table") {
            this.grade_table = true;
        } else if (url.split("/task/grade_table?type=")[1] == "my") {
            this.grade_table = true;
        } else if (url.split("/task/grade_table?type=")[1] == "home_page") {
            this.grade_table = true;
        } else if (url == "/my/account") {
            this.title = "账户";
            this.account = true;
        } else if (url == "/my/reset_password") {
            this.title = "重设密码";
            this.reset_password = true;
        } else if (url == "/my/faq") {
            this.reset_password = true;
        } else if (url == "/my/point_rule") {
            this.point_rule = true;
        } else if (url == "/my/collect") {
            this.collect = true;
        } else if (url == "/my/activation_record") {
            this.activation_record = true;
        } else if (
            type_url[0] == "/my/activation_record" &&
            type_url[1].split("=")[0] == "id" &&
            type_url[1].split("=")[1]
        ) {
            this.activation_record = true;
        } else if (url.split("/my/activation_record/")[1]) {
            this.activation_record_detail = true;
        } else if (url == "/my/regard_allstar") {
            this.regard_allstar = true;
        } else if (url == "/task/add_punch_card_project") {
            this.title = "自訂义打卡";
            this.add_punch_card_project = true;
        } else if (url.split("/task/add_activation_record/read")[1]) {
            this.add_activation_record = true;
        }
        // 登錄成功后 我的頁面頂部不用顯示用戶名
        this.url = this.$route.fullPath;
    },
    methods: {
        login() {
            this.$router.push({
                path: "/login",
            });
        },
        UserMy() {
            this.$router.push({
                path: "/my",
            });
        },
        returnUrl() {
            let url = this.$route.fullPath;
            let type_url = url.split("?");
            // 从培训时间表也返回到首页 有部分样式会影响到 所以用页面刷新跳转的新式
            // let type_url = url.split('?');
            if (url == "/lesson" || url == "/search") {
                location.href = "../";
            } else if (type_url[0] == "/training_schedule") {
                location.href = "../";
            } else {
                this.$router.go(-1);
            }
        },
        returnUrls() {
            this.$router.push({ path: "/search" });
        },
    },
};
</script>
