<template>
  <div>
    <Header/>
    <div class="size-item" v-for="item in size" :key="item">
        <div :style="{'font-size':item+'px'}">
            {{select_font_size}}{{select_font_size}}
        </div>
        <div style="text-align: center; padding: 10px; margin-top: 5px;">
            <button class="clear-btn_size round-btn_size" @click="setSize(item)">{{select_top_font_size}}</button>
        </div>
    </div>
  </div>
</template>

<style>
.round-btn_size{
    line-height: 130%;
    font-size: 16px;
    padding: 7px 20px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
}
.clear-btn_size{
    color: rgb(51, 51, 51);
    width: auto;
    display: initial;
    background: transparent;
}
.size-item{
    color: rgb(51, 51, 51);
    padding: 10px 15px;
    border-top: 1px solid #fff;
    text-align:left;
    padding: 10px 15px;
    border-top: 1px solid #fff;
    margin-top: -1vh;
}
</style>
<script>
    import Header from "../Header";
    export default {
     components:{Header},
     data(){
         return {
             size:[26,24,22,20,18,16,14],
             select_font_size:'',
             select_top_font_size:'',
         }
     },
     created(){
         let lang_id = this.$store.state.lang_id;
        if(lang_id == 1){
            this.select_font_size = this.$zh_ft('select_font_size');
            this.select_top_font_size = this.$zh_ft('select_top_font_size');
        }else{
            this.select_font_size = this.$zh_jt('select_font_size');
            this.select_top_font_size = this.$zh_jt('select_top_font_size');
        }
     },
     methods:{
         setSize(){
             this.$message.success('設定成功');
             this.$router.push({
                 path:'/my'
             });
         }
     }
    }
</script>